.container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 12px;
  align-items: center;

  .label {
    color: var(--text-tertiary);
    font-size: var(--text-md);
    font-weight: var(--regular);
  }
}
