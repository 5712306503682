.title {
  font-size: var(--text-xl);
  font-weight: var(--semibold);
  color: var(--text-primary);
  font-family: var(--font-inter);
}

.subtitle {
  font-size: var(--text-sm);
  font-weight: var(--medium);
  color: var(--text-tertiary);
  font-family: var(--font-inter);
}

.content {
  font-size: var(--text-md);
  font-weight: var(--semibold);
  color: var(--text-secondary);
  font-family: var(--font-inter);
}

.helper {
  font-size: var(--text-md);
  font-family: var(--font-inter);
}
