.container {
  width: 100%;

  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  .image {
    position: relative;

    top: 10%;

    width: auto;
    height: 312px;

    &:hover {
      cursor: pointer;
    }
  }
}
