.section {
  display: flex;
  background-color: var(--bg-secondary);
  justify-content: center;

  padding-block: 96px;

  .container {
    display: flex;
    flex-direction: row;
    gap: 64px;

    width: 100%;
    max-width: 1280px;

    padding-inline: 24px;

    .infoContainer {
      display: flex;
      flex-direction: column;
      gap: 20px;

      max-width: 440px;

      .header {
        display: flex;
        flex-direction: column;
        gap: 12px;

        .category {
          color: var(--text-brand-secondary);
          font-size: var(--text-md);
          font-weight: var(--semibold);
        }

        .title {
          color: var(--text-primary);
          font-size: var(--display-md);
          font-weight: var(--semibold);
        }
      }

      .description {
        color: var(--text-tertiary);
        font-size: var(--text-lg);
        font-weight: var(--regular);
      }
    }

    .cardContainer {
      flex: 1;
    }
  }
}

@media screen and (max-width: 1000px) {
  .section {
    padding-block: 24px;

    .container {
      flex-direction: column;

      .infoContainer {
        .header {
          .category {
            font-size: var(--text-sm);
          }

          .title {
            font-size: var(--display-sm);
          }
        }

        .description {
          font-size: var(--text-md);
        }
      }
    }
  }
}
