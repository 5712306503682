.container {
  display: flex;
  flex-wrap: nowrap;

  gap: 24px;

  max-width: 768px;

  .thumbnail {
    aspect-ratio: 16/11;

    width: 320px;
    height: auto;
    border-radius: 16px;

    object-fit: cover;

    overflow: hidden;
  }

  .contentContainer {
    display: flex;
    flex-direction: column;
    gap: 24px;
    flex: 1;

    .textContainer {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .category {
        color: var(--text-brand-secondary);
        font-size: var(--text-sm);
        font-weight: var(--semibold);
      }

      .title {
        color: var(--text-primary);
        font-size: var(--text-lg);
        font-weight: var(--semibold);
      }

      .description {
        color: var(--text-tertiary);
        font-size: var(--text-md);
        font-weight: var(--regular);
      }
    }

    .authorContainer {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;

      gap: 4px;

      .avatar {
        width: 32px;
        height: 32px;
        border-radius: 50%;
      }

      .author {
        display: flex;
        flex-direction: column;
        gap: 2px;

        .name {
          color: var(--text-primary);
          font-size: var(--text-sm);
          font-weight: var(--semibold);
        }
        .date {
          color: var(--text-tertiary);
          font-size: var(--text-sm);
          font-weight: var(--regular);
        }
      }
    }
  }
}

.row {
  flex-direction: row;
}

.column {
  flex-direction: column;

  .thumbnail {
    width: 100%;
    height: auto;
  }
}

@media screen and (max-width: 1000px) {
  .row {
    flex-direction: column;

    .thumbnail {
      width: 100%;
      height: auto;
    }
  }
}
