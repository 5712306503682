@property --background-color {
  syntax: '<color>';
  inherits: false;
  initial-value: #f2f4f7;
}
.container {
  display: flex;
  flex-direction: column;
  gap: 0;
  align-items: center;
  justify-content: center;

  padding-left: 32px;
  padding-right: 96px;

  height: 100%;

  .item {
    position: relative;

    width: 100%;
    padding-left: 24px;
    padding-block: 16px;

    .content {
      display: flex;
      flex-direction: column;
      gap: 2px;
      opacity: 0.75;

      transition: opacity 0.3s ease-in-out;

      .title {
        color: var(--text-primary);
        font-size: var(--text-xl);
        font-weight: var(--semibold);
      }
      .description {
        color: var(--text-tertiary);
        font-size: var(--text-md);
        font-weight: var(--regular);
      }
    }

    &::after {
      content: '';
      display: block;

      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;

      width: 4px;

      background-color: var(--background-color);

      transition: --background-color 0.3s;
    }
  }

  .item.active {
    .content {
      opacity: 1;
    }
    &::after {
      --background-color: var(--border-brand-solid);
    }
  }
}

@media screen and (max-width: 1000px) {
  .container {
    padding: 0;
  }
}
