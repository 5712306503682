.card {
  background-color: white;

  display: flex;
  flex-direction: column;
  gap: 0;

  width: 100%;
  height: 100%;

  overflow: hidden;

  border-radius: 16px;
  border: 1px solid var(--border-secondary);

  // https://www.joshwcomeau.com/shadow-palette/
  --shadow-color: 0deg 0% 63%;
  --shadow-elevation-low: 0.5px 0.5px 0.9px hsl(var(--shadow-color) / 0.2),
    0.9px 0.8px 1.5px -0.7px hsl(var(--shadow-color) / 0.29),
    2.1px 1.9px 3.6px -1.5px hsl(var(--shadow-color) / 0.38);
  box-shadow: var(--shadow-elevation-low);

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 32px;

    padding: 32px;
    padding-bottom: 40px;

    .header {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 16px;

      .strikedTitle {
        color: var(--text-primary);
        font-size: var(--display-sm);
        font-weight: var(--semibold);
        text-decoration: line-through;
      }
      .title {
        color: var(--text-primary);
        font-size: var(--display-md);
        font-weight: var(--semibold);
      }
    }

    .features {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 16px;

      .feature {
        font-size: var(--text-md);
      }
    }
  }

  .footer {
    background-color: var(--bg-secondary);

    width: 100%;

    padding: 32px;

    border-top: 1px solid var(--border-secondary);
  }
}

@media screen and (max-width: 1000px) {
  .card {
    .content {
      padding: 20px;
      padding-bottom: 32px;
      .header {
        .strikedTitle {
          font-size: var(--text-md);
        }

        .title {
          font-size: var(--text-lg);
          text-align: center;
          white-space: nowrap;
        }
      }

      .features {
        gap: 12px;

        .feature {
          font-size: var(--text-sm);
        }
      }
    }

    .footer {
      padding: 12px;
    }
  }
}
