.container {
  cursor: grab;
  max-width: 1200px;
  padding-inline: 12px;
  align-self: center;

  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 32px;

    padding-inline: 16px;

    .text {
      color: var(--text-primary);
      font-size: var(--display-md);
      font-weight: var(--medium);
      text-align: center;
    }

    .image {
      width: auto;
      height: 80px;
    }
  }

  .indicators {
    position: relative;
    bottom: 0;
    transform: translateY(40px);
    gap: 16px;

    .indicator {
      width: 10px;
      height: 10px;

      border-radius: 50%;

      transition: width 250ms ease;

      background-color: var(--bg-quaternary);

      &[data-active] {
        background-color: var(--brand-color-600);
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .container {
    align-self: auto;

    .item {
      padding-inline: 12px;
      .text {
        font-size: var(--text-lg);
      }

      .image {
        height: 60px;
      }
    }
  }
}
