.card {
  display: flex;
  flex-direction: row;
  gap: 0;
  width: 100%;

  border-radius: 24px;
  overflow: hidden;

  // https://www.joshwcomeau.com/shadow-palette/
  --shadow-color: 0deg 0% 80%;
  --shadow-elevation-high: 0.2px 0.2px 0.4px hsl(var(--shadow-color) / 0.11),
    0.7px 0.7px 1.2px -0.2px hsl(var(--shadow-color) / 0.12),
    1.2px 1.2px 2.1px -0.5px hsl(var(--shadow-color) / 0.14),
    1.8px 1.9px 3.3px -0.7px hsl(var(--shadow-color) / 0.15),
    2.7px 2.8px 4.8px -1px hsl(var(--shadow-color) / 0.16),
    3.9px 4.2px 7.1px -1.2px hsl(var(--shadow-color) / 0.18),
    5.8px 6.1px 10.5px -1.5px hsl(var(--shadow-color) / 0.19),
    8.3px 8.8px 15.1px -1.7px hsl(var(--shadow-color) / 0.2);

  box-shadow: var(--shadow-elevation-high);

  .content {
    width: 100%;
    background-color: var(--bg-brand-section);
    padding: 64px;
  }
  .orderOne {
    order: 1;
  }
}
.columnDirection {
  flex-direction: column !important;
}

@media screen and (max-width: 1279px) {
  .card {
    border-radius: 0;

    .content {
      padding: 32px;
    }
  }
}
