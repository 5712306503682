.container {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  white-space: nowrap;

  align-self: center;

  overflow-x: hidden;

  width: 100%;

  height: 100px;

  mask-image: linear-gradient(
    to right,
    hsl(0 0% 0% / 0),
    hsl(0 0% 0% / 1) 15%,
    hsl(0 0% 0% / 1) 85%,
    hsl(0 0% 0% / 0)
  );

  .slidingContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    white-space: nowrap;
    gap: 0;

    animation: bannermove 20s linear infinite;

    .item {
      object-fit: contain;

      width: auto;
      height: 100%;

      max-width: 120px;
      min-width: 1px;

      margin: 0 12px;
    }
  }
}

@keyframes bannermove {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}

@media screen and (max-width: 1000px) {
  .container {
    height: 80px;
  }
}
