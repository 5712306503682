.container {
  display: flex;
  background-color: var(--bg-secondary);
  justify-content: center;

  padding-block: 32px;
  padding-inline: 16px;
  padding-bottom: 64px;

  .contentContainer {
    display: flex;
    flex-direction: column;
    gap: 32px;

    width: 100%;
    max-width: 1200px;

    .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .titleContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;

        .title {
          color: var(--text-primary);
          font-size: var(--display-md);
          font-weight: var(--bold);
        }
        .category {
          color: var(--text-brand-secondary);
          font-size: var(--text-lg);
          font-weight: var(--semibold);
        }
        .description {
          color: var(--text-tertiary);
          font-size: var(--text-xl);
          font-weight: var(--regular);
        }
      }
    }

    .blogsContainer {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 32px;
      flex-direction: row;
      flex-wrap: nowrap;
    }
  }
}

@media screen and (max-width: 1000px) {
  .container {
    .contentContainer {
      .header {
        flex-direction: column;
        gap: 16px;
        .titleContainer {
          .title {
            font-size: var(--display-xs);
          }
          .category {
            font-size: var(--text-sm);
          }
          .description {
            font-size: var(--text-md);
          }
        }
      }

      .blogsContainer {
        grid-template-columns: repeat(1, 1fr);
        gap: 64px;
      }
    }
  }
}
