.container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;

  gap: 8px;

  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  .author {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .title {
      color: var(--text-primary);
      font-size: var(--text-sm);
      font-weight: var(--semibold);
    }
    .subtitle {
      color: var(--text-tertiary);
      font-size: var(--text-sm);
      font-weight: var(--regular);
    }
  }
}

@media screen and (max-width: 1000px) {
  .container {
    .avatar {
      width: 24px;
      height: 24px;
    }

    .author {
      .title {
        font-size: var(--text-xs);
      }
      .subtitle {
        font-size: var(--text-xs);
      }
    }
  }
}
