.container {
  display: flex;
  gap: var(--mantine-spacing-s4);
  flex-wrap: wrap;
}

.content {
  font-size: var(--text-md);
  font-weight: var(--medium);
  font-family: var(--font-inter);
}
