.container {
  position: absolute;
  top: 100%;
  left: calc(100%);

  transform: translate(0, 75%);
}

.text {
  position: relative;
  text-align: left;
  font-feature-settings: 'salt' on;
  line-height: 1.5;
  height: 1.5em;
  width: 60vw;
  text-wrap: break-spaces;
  white-space: break-spaces;

  color: var(--text-brand-tertiary);
  font-size: var(--display-xs);
  font-family: var(--font-josefin-sans);
  font-style: italic;
  font-weight: var(--bold);

  @media screen and (max-width: 1000px) {
    text-align: center;
  }
}

.icon {
  position: absolute;
  top: 20%;
  left: 0;
  transform: translate(calc(-100% - 8px), -50%);
}
