.container {
  width: 100%;
  background-color: var(--bg-secondary);
}

.badgeContainer {
  display: flex;
  flex-direction: column;
  gap: var(--mantine-spacing-s6);
}

@media screen and (max-width: 1000px) {
  .container {
    padding-top: 16px;
  }
}
