.container {
  max-width: 1200px;
  align-self: center;
  padding: 24px;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;

    .title {
      color: var(--text-primary-on-brand);
      font-size: var(--display-md);
      font-weight: var(--semibold);
    }

    .description {
      color: var(--text-tertiary-on-brand);
      font-size: var(--text-xl);
      font-weight: var(--regular);
    }

    .cta {
      color: var(--text-primary);
      font-size: var(--text-md);
      font-weight: var(--semibold);
    }
  }

  .image {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

@media screen and (max-width: 1279px) {
  .container {
    padding: 0;
  }
}
