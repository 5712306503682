.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 64px;

  padding-inline: 24px;

  max-width: 1280px;
  align-self: center;

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    .title {
      color: var(--text-primary);
      font-size: var(--display-md);
      font-weight: var(--semibold);
    }
    .subtitle {
      color: var(--text-tertiary);
      font-size: var(--text-xl);
      font-weight: var(--regular);
    }
  }

  .features {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 32px;
    gap: 32px;
    justify-content: center;

    .feature {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;

      max-width: 30%;
      min-width: 300px;

      .featureIcon {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        width: 48px;
        aspect-ratio: 1;

        border-radius: 10px;

        // https://www.joshwcomeau.com/shadow-palette/
        --shadow-color: 0deg 0% 63%;
        --shadow-elevation-low: 0.5px 0.5px 0.9px hsl(var(--shadow-color) / 0.2),
          0.9px 0.8px 1.5px -0.7px hsl(var(--shadow-color) / 0.29),
          2.1px 1.9px 3.6px -1.5px hsl(var(--shadow-color) / 0.38);
        box-shadow: var(--shadow-elevation-low);

        .icon {
          width: 24px;
          aspect-ratio: 1;
        }
      }

      .featureTitle {
        color: var(--text-primary);
        font-size: var(--text-xl);
        font-weight: var(--semibold);

        text-align: center;
      }

      .featureDescription {
        color: var(--text-tertiary);
        font-size: var(--text-md);
        font-weight: var(--regular);

        text-align: center;
      }
    }
  }
}

@media screen and (max-width: 1279px) {
  .container {
    gap: 32px;
  }
}
