.container {
  display: flex;
  flex-direction: column;
  gap: 48px;
  width: 100%;
  max-width: 1200px;

  align-self: center;

  .title {
    color: var(--text-primary);
    font-size: var(--display-md);
    font-weight: var(--semibold);
    text-align: center;
  }

  .items {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 64px;

    .item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      text-align: center;
      flex: 1;

      gap: 16px;

      .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        gap: 16px;

        .image {
          width: 64px;
          aspect-ratio: 1;
          border-radius: 50%;
          overflow: hidden;
          object-fit: cover;
        }

        .testimony {
          color: var(--text-primary);
          font-size: var(--text-lg);
          font-weight: var(--medium);
        }
      }

      .author {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 8px;
        color: var(--text-primary);

        .authorInfo {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 8px;

          .authorName {
            font-size: 16px;
            font-weight: 600;

            color: var(--text-primary);
            font-size: var(--text-lg);
            font-weight: var(--semibold);
          }

          .authorCompany {
            color: var(--text-tertiary);
            font-size: var(--text-md);
            font-weight: var(--regular);
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1279px) {
  .container {
    gap: 24px;

    .items {
      flex-direction: column;
      padding-inline: 32px;
      .item {
        max-width: 355px;
        align-self: center;
      }
    }
  }
}
