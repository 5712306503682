.container {
  width: 100%;
  max-width: 1280px;
  align-self: center;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  gap: 16px;

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 64px;
    width: 100%;

    .header {
      display: flex;
      flex-direction: column;
      gap: 20px;
      align-items: flex-start;

      padding-left: 32px;

      justify-content: space-between;

      width: 100%;

      .title {
        color: var(--text-primary);
        font-size: var(--display-md);
        font-weight: var(--semibold);

        white-space: nowrap;
      }
      .description {
        color: var(--text-tertiary);
        font-size: var(--text-xl);
        font-weight: var(--regular);
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .container {
    .content {
      gap: 32px;
      .header {
        padding: 0;

        .title {
          white-space: unset;
        }
      }
    }

    .image {
      display: none;
    }

    padding: 24px;
  }
}
